import React from "react"
import { Router } from "@reach/router"
import { connect } from "react-redux"
import * as actions from "@redux/actions"

import { ClientRoute, AdminRoute } from "@components/PrivateRoute"
import Profile from "./profile"
import CreateCheckin from "./profile/create-checkin"
import PreviousCheckins from "./profile/previous-checkins"
import PaymentSettings from "./profile/payment-settings"
import Admin from "./admin"
import Login from "./login"

const AppPage = ({ authenticated, user }) => (
  <Router>
    <ClientRoute
      path="/app/profile"
      component={Profile}
      authenticated={authenticated}
      user={user}
    />
    <ClientRoute
      path="/app/profile/create-checkin"
      component={CreateCheckin}
      authenticated={authenticated}
      user={user}
    />
    <ClientRoute
      path="/app/profile/previous-checkins"
      component={PreviousCheckins}
      authenticated={authenticated}
      user={user}
    />
    <ClientRoute
      path="/app/profile/payment-settings"
      component={PaymentSettings}
      authenticated={authenticated}
      user={user}
    />
    <AdminRoute
      path="/app/admin"
      component={Admin}
      authenticated={authenticated}
      user={user}
    />
    <Login path="/app/login" />
    <Login path="/app" />
  </Router>
)

const mapStateToProps = state => {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps, actions)(AppPage)
