import React from "react"
import { navigate } from "gatsby"

const AdminRoute = ({
  user,
  authenticated,
  component: Component,
  location,
  ...rest
}) => {
  if (!authenticated && location.pathname !== `/app/login`) {
    navigate("/app/login", { replace: true })
    return null
  }
  if (!user?.admin) {
    navigate("/app/profile", { replace: true })
    return null
  }
  return <Component {...rest} />
}

export default AdminRoute
